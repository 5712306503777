import React from 'react';
import { graphql, Link } from 'gatsby';
import {
  Box,
  Heading,
  Text,
  Flex,
  Container,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
} from '@chakra-ui/react';
import { FaFax, FaPhoneAlt } from 'react-icons/fa';
import ReactMarkdown from 'react-markdown';
import { Layout, LinkEmail, LinkPhone } from '../components/index';

const formatePhone = (phoneNumberString) => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
};

export default function Template({ data }) {
  const { markdownRemark } = data;
  // const path = data.fields.path
  const { frontmatter, html } = markdownRemark;
  const formatedPhone = formatePhone(frontmatter.phone);
  const formatedFax = formatePhone(frontmatter.fax);

  return (
    <Layout>
      <Box minHeight="100vh" bg="brand.one" py={10}>
        <Container maxW="container.md">
          <Box mt={6} mb={10}>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/">
                  <BreadcrumbLink>Home</BreadcrumbLink>
                </Link>
              </BreadcrumbItem>

              <BreadcrumbItem>
                <Link to="/professionals">
                  <BreadcrumbLink>Professionals</BreadcrumbLink>
                </Link>
              </BreadcrumbItem>

              <BreadcrumbItem isCurrentPage>
                <BreadcrumbLink>{frontmatter.name}</BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
          </Box>
          <Flex justifyContent="space-between">
            <Box>
              <Heading as="h3" size="xl" textTransform="uppercase">
                {frontmatter.name}
              </Heading>
              <Heading as="h4" size="lg" mb={3}>
                {frontmatter.title}
              </Heading>
            </Box>
            <Box>
              {frontmatter.email ? (
                <Text as="h4" size="sm" mb={3}>
                  <Flex
                    alignItems="center"
                    width="140px"
                    justifyContent="space-between"
                  >
                    <GrMail /> <LinkEmail>{frontmatter.email}</LinkEmail>
                  </Flex>
                </Text>
              ) : (
                <div />
              )}
              {frontmatter.phone !== '' ? (
                <Text as="h4" size="sm" mb={3}>
                  <Flex
                    alignItems="center"
                    width="140px"
                    justifyContent="space-between"
                  >
                    <FaPhoneAlt /> <LinkPhone>{formatedPhone}</LinkPhone>
                  </Flex>
                </Text>
              ) : (
                <div />
              )}

              {frontmatter.fax !== '' ? (
                <Text as="h4" size="sm" mb={3}>
                  <Flex
                    alignItems="center"
                    width="140px"
                    justifyContent="space-between"
                  >
                    <FaFax /> <LinkPhone>{formatedFax}</LinkPhone>
                  </Flex>
                </Text>
              ) : (
                <div />
              )}
            </Box>
          </Flex>

          <Heading
            mt={6}
            mb={4}
            as="h4"
            size="lg"
            // textTransform="uppercase"
          >
            Bio
          </Heading>
          <Text>
            <ReactMarkdown source={frontmatter.bio} />
          </Text>
          <Heading
            mt={6}
            mb={4}
            as="h4"
            size="lg"
            // textTransform="uppercase"
            mb={3}
          >
            Experience
          </Heading>

          <Text>
            <ReactMarkdown source={frontmatter.experience} />
          </Text>
        </Container>
      </Box>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(fields: { path: { eq: $path } }) {
      html
      fields {
        path
      }
      frontmatter {
        profileImg {
          childrenImageSharp {
            fixed(width: 500) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        name
        title
        phone
        fax
        bio
        experience
      }
    }
  }
`;
